import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"
import { PropertyOwnerRequest } from "../sections/nomenclature/property-owners/PropertyOwnerConfiguration"
import { CustomFile, PersonalData } from "rentzz"
import { PropertyOwnerRepresentative } from "../queries/property-owners"

export const useAddPropertyOwnerMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: PropertyOwnerRequest }) => Api.addPropertyOwner(data),

        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
            }
        },
    })
}

export const useUpdatePropertyOwnerMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ propertyOwnerId, operations }: { propertyOwnerId: string; operations: jsonpatch.Operation[] }) =>
            Api.updatePropertyOwner({ operations, propertyOwnerId }),

        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
            }
        },
    })
}

export const usePropertyOwnerAssociateProperty = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            propertyOwnerId,
            propertyId,
            ownershipPercentage,
        }: {
            propertyOwnerId: number
            propertyId: number
            ownershipPercentage: number
        }) => Api.associatePropertyToOwner(propertyOwnerId, propertyId, ownershipPercentage),

        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
            }
        },
    })
}

export const deletePropertyOwnerMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: async (propertyOwnerId: number) => await Api.deletePropertyOwner(propertyOwnerId),
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
        },
    })
}

export const deletePropertyFromOwnerMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: async ({ propertyOwnerId, propertyId }: { propertyOwnerId: number; propertyId: number }) =>
            await Api.deletePropertyFromOwner(propertyOwnerId, propertyId),
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
        },
    })
}

export const updatePropertyOwnerPropertyPercentageMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            operations,
            propertyOwnerId,
            propertyId,
        }: {
            operations: jsonpatch.Operation[]
            propertyOwnerId: number
            propertyId: number
        }) =>
            Api.updatePropertyOwnerProperty({
                operations,
                propertyOwnerId,
                propertyId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
            }
        },
    })
}

// Define IdentifierType enum
export enum IdentifierType {
    CI,
    Passport,
    Custom,
}

interface AddPropertyOwnerRepresentativeRequest extends PersonalData {
    propertyOwnerId: number
    startDate: string
    endDate: string
    identificationDocumentSeries?: string
    identificationDocumentNumber?: string
    identificationDocumentIssuer?: string
    identificationDocumentIssuedDate?: string
    identifierType: IdentifierType
    customIdentifierType?: string
    files?: CustomFile[] // New field for file uploads
}

// Export the interface for use in Api.ts
export type { AddPropertyOwnerRepresentativeRequest }

export const useAddPropertyOwnerRepresentativeMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: async (data: AddPropertyOwnerRepresentativeRequest) => {
            // Call the API directly
            await Api.addPropertyOwnerRepresentative(data.propertyOwnerId, data)
        },
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
            }
        },
    })
}

export const useDeletePropertyOwnerRepresentativeMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: async ({ propertyOwnerId, representativeId }: { propertyOwnerId: number; representativeId: string }) => {
            // Use direct API call to delete the representative instead of JSON patch operation
            await Api.deletePropertyOwnerRepresentative(propertyOwnerId, representativeId)
        },
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
            }
        },
    })
}

export const useUpdatePropertyOwnerRepresentativeMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: async ({
            propertyOwnerId,
            representativeId,
            originalRepresentative,
            ...data
        }: AddPropertyOwnerRepresentativeRequest & { representativeId: string; originalRepresentative: PropertyOwnerRepresentative }) => {
            // Create a deep clone of the original object
            const source = JSON.parse(
                JSON.stringify(originalRepresentative, (key, value) => {
                    // Convert DateTime objects to ISO strings
                    if (value && typeof value === "object" && value.isLuxonDateTime) {
                        return value.toISO()
                    }
                    return value
                }),
            )

            // Remove metadata fields
            delete source.id
            delete source.createdAt
            delete source.lastModifiedAt
            delete source.files

            // Extract owner properties and rest of data
            const { files: _, ...restData } = data

            // Create target object with flat structure matching the API
            const target = {
                ...restData,
            }

            // Generate operations
            const operations = jsonpatch.compare(source, target)

            // Call API with the operations
            await Api.updatePropertyOwnerRepresentative(propertyOwnerId, representativeId, operations)
        },
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.PropertyOwners, ComplexQueryIDs.PropertyOwnerDetails])
            }
        },
    })
}
